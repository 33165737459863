<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%" :class="{ disabled: loadingConnectionCheck }">
        <!-- Connection configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidState" />
                </span>
            </template>
            <div>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipement.MQTT.address') + ' *'" label-for="address">
                            <b-form-input id="address" v-model="address" :state="addressState" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipments.MQTT.port') + ' *'" label-for="port">
                            <b-form-input
                                type="number"
                                min="1"
                                max="65535"
                                id="port"
                                v-model="port"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.port = 1883;
                                        } else if (value > 65535) {
                                            this.port = 65535;
                                        } else if (value < 1) {
                                            this.port = 1;
                                        }
                                    }
                                "
                                :state="portState"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-button @click="onCheckConnection" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionRead }">
                            {{ $t('equipment.opcua.checkConnection') }}
                            <font-awesome-icon v-if="loadingConnectionCheck" icon="fas fa-spinner-third" class="fa-lg fa-spin-custom" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-tab>
        <!-- Item configuration -->
        <b-tab>
            <template #title>
                {{ $t('Variables') }}
                <b-badge pill variant="info">{{
                    groups
                        .map((gr) => gr.Items.length)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0)
                }}</b-badge>
            </template>
            <!-- <template #title>
                <span class="tabHeaderText">{{$t('Variables')}}</span>
            </template> -->
            <ItemConfiguration
                ref="itemConfiguration"
                :columns="itemColumns"
                :groups="groups"
                :OnItemBrowse="onItemBrowse"
                :separateGroupConfig="true"
                :groupConfiguration="groupConfiguration"
                :readonly="!this.$store.state.auth.user.permissions.PermissionWrite"
                :updateMode="updateMode"
                :RefreshVarCounter="refreshVariablesCounter"
            />
        </b-tab>
    </b-tabs>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import ToastAlert from '@/utils/ToastAlert';
import { uuid } from 'vue-uuid';

export default {
    name: 'ConfigMQTT',
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    components: {
        ItemConfiguration,
    },
    async mounted() {},
    data() {
        return {
            loadingConnectionCheck: false,
            address: this.equipment?.Address ?? 'localhost',
            port: this.equipment?.Port ?? '1883',
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name'), required: true },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type'), required: true },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address'), required: true },
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, RefreshRate: 500, Items: [] }],
            groupConfiguration: {
                rows: [
                    {
                        columns: [{ text: this.$t('Refresh rate'), variable: 'RefreshRate', inputType: 'number', cols: '6', min: '100', max: '99999', defaultValue: 500 }],
                    },
                ],
            },
        };
    },
    computed: {
        invalidState() {
            return this.addressState === false || this.portState === false;
        },
        addressState() {
            return this.address == null || this.address == '' ? false : null;
        },
        portState() {
            return this.port == null || this.port == '' ? false : null;
        },
    },
    methods: {
        async saveEquipmentCommunication() {
            await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.address = this.address;
            this.equipment.port = this.port;
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {},
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        async onCheckConnection(e) {
            this.loadingConnectionCheck = true;
            const result = await ProdComEquipmentService.MQTTCheckConnection(this.validate());
            if (result.ret.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
            } else {
                if (!result.ret.Connected) {
                    if (result.ret.ConnectionFailedMessage !== '') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
                    }
                } else {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.MQTT.connectionSuccess'), 'success', this.$t('ConnectionSuccess')));
                }
            }
            this.loadingConnectionCheck = false;
        },
        /**
         * Returns the correctly formated object of connection parameters
         */
        validate() {
            if (this.invalidState) return null;
            return {
                address: this.address,
                port: this.port,
                name: this.newEquipmentName,
            };
        },
        /**
         * Called when browsing for items in configuration. Returns the list of available items
         */
        async onItemBrowse(e) {
            // TODO: search for items in MQTT conf
            try {
                const data = await ProdComEquipmentService.MQTTBrowseItems(this.validate());
                if (data.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    return [];
                } else {
                    return data.ret;
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
    },
};
</script>