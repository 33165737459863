<template>
    <b-modal :id="id" v-b-modal.modal-multi-2 size="xl"
        @cancel="onItemCancel" @ok="onItemOk" @show="onItemShow"
        :title="$t('Search items')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
        <b-form>
            <b-row>
                <b-col cols="5">
                    <div class="text-center">
                        <font-awesome-icon v-if="!searchingForItems.is" icon="fas fa-redo" class="cursor-pointer" v-on:click="refreshItemList"/>
                        <font-awesome-icon v-if="searchingForItems.is" icon="fas fa-spinner-third" class="fa-spin-custom"/>
                        <span class="variable-counter" v-if="variableCounter.is">{{ this.variableCounter.content +' '+  $t('Variable.Found')}}</span>
                    </div>
                    <b-card class="card-items-scroll">
                        <TreeView id="data-treeview-items" :item="itemListServer" :itemSelect="onSelectItemServer" :multiSelect="true"/>
                    </b-card>
                </b-col>
                <b-col cols="2">
                    <b-container  class="h-100">
                        <b-row class="h-100">
                            <b-col class="h-100 d-table">
                                <div class="text-center d-table-cell align-middle">
                                    <font-awesome-icon icon="fas fa-arrow-right" class="cursor-pointer" style="margin-bottom: 5px" v-on:click="addSelectedItems"/>
                                    <br>
                                    <font-awesome-icon icon="fas fa-arrow-left" class="cursor-pointer" style="margin-top: 5px" v-on:click="removeSelectedItems"/>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
                <b-col cols="5">
                    <div class="text-center">
                        <span>{{ $t('Selected items') }}</span>
                    </div>
                    <b-card class="card-items-scroll">
                        <TreeView id="data-treeview-items-client" :item="itemListClient" :itemSelect="onSelectItemClient" :multiSelect="true"/>
                    </b-card>
                </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>
<script>

import TreeView from "@/components/TreeView.vue";
import ProdComEquipmentService from "@/services/prodcom.equipments.service.js";

export default {
    name: "SelectItemListWizard",
    components: {
        TreeView
    },
    props: {
        /**
         * Function called when loading the component. Must be asynchronous.
         */
        OnLoad: Function,
        OnOk: Function,
        OnCancel: Function,
        id: String,
        RefreshVarCounter: Function
    },
    data(){
        return {
            searchingForItems: {is : false},
            itemListServer: [],
            selectedItemServer: [],
            itemListClient: [],
            selectedItemClient: [],
            variableCounter:{is : false, content : 0},
        }
    },
    methods:{
        async onItemCancel(e){
            if(this.OnCancel != null) this.OnCancel();
        },
        async onItemOk(e){
            if(this.OnOk != null) this.OnOk(this.itemListClient);
        },
        async onItemShow(e){
            this.searchingForItems.is = true;
            this.itemListClient = []
            this.myCounterVariables = 0
            if(this.OnLoad != null)     {
                const onLoadPromise = this.OnLoad(this.itemListClient);
                let refreshVariablePromise =  this.RefreshVarCounter(this.searchingForItems, this.variableCounter);
                await Promise.race([refreshVariablePromise, onLoadPromise]);
                this.itemListServer = await onLoadPromise;
            }
            this.variableCounter.content = this.lenServerVariables(this.itemListServer)
            this.searchingForItems.is = false;
        },
        async refreshItemList(e){

        },
        async onSelectItemServer(data){
            this.selectedItemServer = data;
        },
        async onSelectItemClient(data){
            this.selectedItemClient = data;
        },
        async addSelectedItems(e){
            this.addSelectedItemRecurse(this.selectedItemServer, this.itemListClient);
        },
        /**
         * Removes the selected items from the right part list.
         */
        async removeSelectedItems(e){
            for(const i of this.selectedItemsFromClient){
                const itemInSelection = this.selectedItems.findIndex((it) => it.id === i.id);
                if(itemInSelection >= 0) this.selectedItems.splice(itemInSelection, 1);
            }
        },
        /**
         * Recurse add item into destination (flatten structure).
         */
        async addSelectedItemRecurse(item, destination){
            for(const i of item){
                if(i.items != null && i.items.length > 0){
                    this.addSelectedItemRecurse(i.items, destination);
                }else{
                    let iconColor = "#f57c00";
                    if (i.obj.Type == null || i.obj.Type === "" ) iconColor = "#A9A9A9";
                    destination.push({id: i.id, text: i.obj.Name+" ("+i.obj.Address+")", icon: "fas fa-tag", iconColor: iconColor, obj: i.obj})
                }
            }
        },
        lenServerVariables(node){
            let temp_len = 0
            for (const element in node) {
                if(node[element].items == null || node[element].items.length == 0){
                    temp_len++;
                }else{
                    temp_len+= this.lenServerVariables(node[element].items)
                }
            }
            return temp_len;
        }
    }
}
</script>
<style lang="scss">
.card-items-scroll{
    max-height: 400px;
    overflow-y: auto;
}
.variable-counter {
  margin-left: 0.5em; /* Ajouter une marge à gauche pour l'espace entre l'icône et la div */
}
</style>