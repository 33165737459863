import Vue from 'vue';
import { BootstrapVue, BVToastPlugin } from 'bootstrap-vue';
import i18n from './i18n';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import constants from '@/utils/constants';
// FontAwesome icons
import '@/icons.js';
import '@/assets/css/style.scss';
import UUID from 'vue-uuid';
import ProdJsTools from '@/utils/ProdJsTools';

import 'jquery-ui/themes/base/all.css';
import '@devexpress/analytics-core/dist/css/dx-querybuilder.css';
import 'devexpress-reporting/dist/css/dx-webdocumentviewer.css';
import 'devexpress-reporting/dist/css/dx-reportdesigner.css';
import 'devexpress-reporting/dist/css/dx-webdocumentviewer.css';
import 'devexpress-diagram/dist/dx-diagram.min.css';

// Define a plugin for the constants to be globally available at this.$constants
const constantsPlugin = {
    install(Vue, options) {
        Vue.prototype.$constants = constants;
    },
};
Vue.use(constantsPlugin);

Vue.use(UUID);

//require('json.date-extensions');
import '@/utils/extends.js';

Vue.use(BootstrapVue);

// Toast
Vue.use(BVToastPlugin);

Vue.config.productionTip = false;

// Global app tools
Vue.prototype.$ProdJsTools = ProdJsTools;

// Role levels
Vue.prototype.$RoleLevels = {
    SuperAdmin: 0,
    Admin: 1,
    SuperUser: 2,
    User: 3,
    Guest: 4,
};

// License modes
Vue.prototype.$LicenseModes = {
    Trial: 0,
    Educational: 1,
    Rental: 2,
    Purchase: 3,
};
Vue.prototype.$LicenseModesLabel = {
    0: 'Trial',
    1: 'Educational',
    2: 'Rental',
    3: 'Purchase',
    4: 'ProdComReader1000',
    5: 'ProdComReaderFull',
    6: 'ProdCom1000',
    7: 'ProdComFull',
};

// License options
Vue.prototype.$LicenseOptions = {
    Consignation: 0,
    StatisticalProcessControl: 1,
    Graphs: 2,
    HMI: 3,
};
Vue.prototype.$LicenseOptionsLabel = {
    0: 'Value write',
    1: 'Statistical Process Control',
    2: 'Dynamic Graphs',
    3: 'HMI',
};

// Global mixins
const inputsMixin = {
    mounted() {
        if (this.$options && this.$options.name == 'BFormInput' && !this.maxlength && (this.type == 'text' || this.type == 'password')) {
            if (!this.$el.hasAttribute('maxlength')) this.$el.setAttribute('maxlength', 128);
        }
    },
};
Vue.mixin(inputsMixin);

// Global events bus
const EventBus = new Vue();
Vue.prototype.$EventBus = EventBus;

(async () => {
    // Get global app settings
    const res = await fetch('./settings.json');
    const settings = await res.json();

    // Check for Api URL
    let apiRes = await fetch(`${window.location.origin}/api/IsAlive`);
    let apiCheck = await apiRes.text();
    if (apiCheck == 'prodcom-api-alive') {
        settings.API_URL = `${window.location.origin}/api`;
        // TODO
        apiRes = await fetch(`${window.location.origin}/api/ProductName`);
        let productFullName = await apiRes.text();
        if (productFullName != '') settings.PRODUCT_FULL_NAME = 'Revealent Connect';
        else settings.PRODUCT_FULL_NAME = 'UNKNOWN PRODUCT';
        // Get settings from api
        settings.PRIMARY_COLOR = '#1ca8dd';
        settings.PRIMARY_COLOR_CHANGE_ALLOWED = false;

        //apiRes = await fetch(`${window.location.origin}/api/getsettings`);
        //let apiSettings = await apiRes.json();
        // settings.MAX_REFRESH_PIVOTGRID_CELLS = apiSettings.MAX_REFRESH_PIVOTGRID_CELLS;
        // settings.MAX_UPLOAD_FILE_SIZE = apiSettings.MAX_UPLOAD_FILE_SIZE;
        // settings.PRIMARY_COLOR = "#1ca8dd";
        // settings.PRIMARY_COLOR_CHANGE_ALLOWED = apiSettings.PRIMARY_COLOR_CHANGE_ALLOWED == 'true';

        // Get license fom api
        const fetchLicense = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Method: 'license.getLicense',
                Parameters: {},
            }),
        };
        apiRes = await fetch(settings.API_URL, fetchLicense);
        const data = await apiRes.json();
        const license = data.ret;
        settings.LICENSE = license;
    } else {
        // if not reachable on same domain test if api is reachable on url from settings
        try {
            apiRes = await fetch(`${settings.API_URL}/IsAlive`);
            apiCheck = await apiRes.text();
            if (apiCheck != 'prodcom-api-alive') alert('Server does not respond, please check settings');
            else {
                apiRes = await fetch(`${settings.API_URL}/ProductName`);
                let productFullName = await apiRes.text();
                if (productFullName != '') settings.PRODUCT_FULL_NAME = 'Revealent Connect';
                else settings.PRODUCT_FULL_NAME = 'UNKNOWN PRODUCT';
                // Get settings from api
                settings.PRIMARY_COLOR = '#1ca8dd';
                settings.PRIMARY_COLOR_CHANGE_ALLOWED = false;
                /*apiRes = await fetch(`${settings.API_URL}/getsettings`);
                let apiSettings = await apiRes.json();
                settings.MAX_REFRESH_PIVOTGRID_CELLS = apiSettings.MAX_REFRESH_PIVOTGRID_CELLS;
                settings.MAX_UPLOAD_FILE_SIZE = apiSettings.MAX_UPLOAD_FILE_SIZE;
                settings.PRIMARY_COLOR = apiSettings.PRIMARY_COLOR;
                settings.PRIMARY_COLOR_CHANGE_ALLOWED = apiSettings.PRIMARY_COLOR_CHANGE_ALLOWED == 'true';*/

                // Get license fom api
                const fetchLicense = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Method: 'license.getLicense',
                        Parameters: {},
                    }),
                };
                apiRes = await fetch(settings.API_URL, fetchLicense);
                const data = await apiRes.json();
                const license = data.ret;
                settings.LICENSE = license;
            }
        } catch (error) {
            alert('Server does not respond (in error : ' + error + '), please check settings?');
        }
    }

    if (!settings.LICENSE) store.commit('updateMainMode', 'UNKNOWN');
    else if (!settings.LICENSE.Options[Vue.prototype.$LicenseOptions.Dashboards]) {
        if (settings.LICENSE.Options[Vue.prototype.$LicenseOptions.Reports]) store.commit('updateMainMode', 'REPORTS');
        else if (settings.LICENSE.Options[Vue.prototype.$LicenseOptions.Designer]) store.commit('updateMainMode', 'DESIGNER');
        else store.commit('updateMainMode', 'UNKNOWN');
    }
    store.commit('updateSettings', settings);

    const app = new Vue({
        i18n,
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');

    router.$app = app;
})();
