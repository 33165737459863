<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%" :class="{ disabled: loadingConnectionCheck }">
        <!-- Connection configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="ipAddressState == false" />
                </span>
            </template>
            <div>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('ipAddress') + ' *'" label-for="ipAddress">
                            <b-form-input id="ipAddress" :state="ipAddressState" v-model="ipAddress" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('Port') + ' *'" label-for="port">
                            <b-form-input
                                id="port"
                                min="1"
                                max="65535"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.port = 9600;
                                        } else if (value > 65535) {
                                            this.port = 65535;
                                        } else if (value < 1) {
                                            this.port = 1;
                                        }
                                    }
                                "
                                v-model="port"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <b-form-group :label="$t('equipment.omron.sourceNode') + ' *'" label-for="sourceNode">
                            <b-form-input
                                id="posourceNodert"
                                min="0"
                                max="255"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.sourceNode = 0;
                                        } else if (value > 255) {
                                            this.sourceNode = 255;
                                        } else if (value < 0) {
                                            this.sourceNode = 0;
                                        }
                                    }
                                "
                                v-model="sourceNode"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group :label="$t('equipment.omron.destinationNode') + ' *'" label-for="destinatioNode">
                            <b-form-input
                                id="destinatioNode"
                                min="0"
                                max="255"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.destinationNode = 0;
                                        } else if (value > 255) {
                                            this.destinationNode = 255;
                                        } else if (value < 0) {
                                            this.destinationNode = 0;
                                        }
                                    }
                                "
                                v-model="destinationNode"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group :label="$t('equipment.omron.retries') + ' *'" label-for="retries">
                            <b-form-input
                                id="retries"
                                min="0"
                                max="255"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.retries = 2;
                                        } else if (value > 255) {
                                            this.retries = 255;
                                        } else if (value < 0) {
                                            this.retries = 0;
                                        }
                                    }
                                "
                                v-model="retries"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <b-form-group :label="$t('Refresh rate') + ' * (ms)'" label-for="pollingPeriod">
                            <b-form-input
                                id="pollingPeriod"
                                min="100"
                                max="120000"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.pollingPeriod = 500;
                                        } else if (value > 120000) {
                                            this.pollingPeriod = 120000;
                                        } else if (value < 100) {
                                            this.pollingPeriod = 100;
                                        }
                                    }
                                "
                                v-model="pollingPeriod"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group :label="$t('Timeout') + ' * (ms)'" label-for="timeout">
                            <b-form-input
                                id="timeout"
                                min="100"
                                max="120000"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.timeout = 1000;
                                        } else if (value > 120000) {
                                            this.timeout = 120000;
                                        } else if (value < 100) {
                                            this.timeout = 100;
                                        }
                                    }
                                "
                                v-model="timeout"
                                type="number"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-tab>
        <!-- Item configuration -->
        <b-tab>
            <template #title>
                {{ $t('Variables') }}
                <b-badge pill variant="info">{{
                    groups
                        .map((gr) => gr.Items.length)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0)
                }}</b-badge>
            </template>
            <ItemConfiguration
                ref="itemConfiguration"
                :columns="itemColumns"
                :groups="groups"
                :separateGroupConfig="false"
                :groupConfiguration="groupConfiguration"
                :readonly="!this.$store.state.auth.user.permissions.PermissionWrite"
                :updateMode="updateMode"
                :RefreshVarCounter="refreshVariablesCounter"
            />
        </b-tab>
    </b-tabs>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import ToastAlert from '@/utils/ToastAlert';
import { uuid } from 'vue-uuid';

export default {
    name: 'ConfigOmronFins',
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    components: {
        ItemConfiguration,
    },
    watch: {
        async selectedSecurityMode(newVal, oldVal) {
            if (newVal == 1) {
                // None
                this.selectedSecurityPolicy = 'None';
            } else {
                // every other
                this.selectedSecurityPolicy = 'Basic128RSA15';
            }
            this.availableSecurityPolicies = [
                { value: 'None', text: 'None', disabled: newVal !== 1 },
                { value: 'Basic128RSA15', text: 'Basic128RSA15', disabled: newVal === 1 },
                { value: 'Basic256', text: 'Basic256', disabled: newVal === 1 },
                { value: 'Basic256Sha256', text: 'Basic256Sha256', disabled: newVal === 1 },
            ];
        },
    },
    computed: {
        invalidState() {
            return this.ipAddressState === false;
        },
        ipAddressState() {
            return this.ipAddress == '' ? false : null;
        },
    },
    data() {
        return {
            ipAddress: this.equipment?.IpAddress ?? '127.0.0.1',
            port: this.equipment?.Port ?? 9600,
            sourceNode: this.equipment?.SourceNode ?? 0,
            destinationNode: this.equipment?.DestinationNode ?? 0,
            pollingPeriod: this.equipment?.RefreshRate ?? 500,
            timeout: this.equipment?.Timeout ?? 1000,
            retries: this.equipment?.Retries ?? 2,
            loadingConnectionCheck: false,
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name'), required: true },
                { key: 'Zone', dataField: 'ZoneName', dataType: 'string', caption: this.$t('Zone'), required: true, options: ['DM', 'CIO'] },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address'), required: true },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type'), required: true, options: ['Bool', 'Word'] },
                { key: 'ValueIndex', dataField: 'ValueIndex', dataType: 'integer', caption: this.$t('Bit'), required: true },
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, Items: [] }],
            groupConfiguration: {
                rows: [],
            },
        };
    },
    methods: {
        async saveEquipmentCommunication() {
            await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.SelectedPlc = this.selectedPlc;
            this.equipment.SelectedConnectionType = this.selectedConnectionType;
            this.equipment.IpAddress = this.ipAddress;
            this.equipment.RackNumber = this.rackNumber;
            this.equipment.RackSlot = this.rackSlot;
            this.equipment.LocalTSAP = this.localTSAP;
            this.equipment.RemoteTSAP = this.remoteTSAP;
            this.equipment.PollingPeriod = this.pollingPeriod;
            this.equipment.Timeout = this.timeout;
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {},
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        async onCheckConnection(e) {
            this.loadingConnectionCheck = true;
            const result = await ProdComEquipmentService.s7CheckConnection(this.validate());
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            } else {
                if (!result.ret.Connected) {
                    if (result.ConnectionFailedMessage !== '') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
                    }
                } else {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.s7.connectionSuccess') + '(' + result.ret.ConnectionFailedMessage + ')', 'success', this.$t('ConnectionSuccess')));
                }
            }
            this.loadingConnectionCheck = false;
        },
        /**
         * Returns the correctly formated object of connection parameters
         */
        validate() {
            if (this.invalidState) return null;
            return {
                SelectedPlc: this.selectedPlc,
                SelectedConnectionType: this.selectedConnectionType,
                IpAddress: this.ipAddress,
                RackNumber: this.rackNumber,
                RackSlot: this.rackSlot,
                LocalTSAP: this.localTSAP,
                RemoteTSAP: this.remoteTSAP,
                PollingPeriod: this.pollingPeriod,
                Timeout: this.timeout,
            };
        },
    },
};
</script>