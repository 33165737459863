var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{class:{ disabled: _vm.loadingConnectionCheck },staticStyle:{"width":"100%"},attrs:{"pills":"","card":"","h-100":"","d-flex":"","flex-column":""}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"tabHeaderText"},[_vm._v(" "+_vm._s(_vm.$t('Connection'))+" "),(_vm.settingsState == false)?_c('font-awesome-icon',{staticClass:"ml-1 text-danger",attrs:{"icon":"fa fa-warning"}}):_vm._e()],1)]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('PLC') + ' *',"label-for":"selectedPlc"}},[_c('b-form-select',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"selectedPlc","options":_vm.availablePlcs},model:{value:(_vm.selectedPlc),callback:function ($$v) {_vm.selectedPlc=$$v},expression:"selectedPlc"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('equipment.s7.description_' + _vm.selectedPlc))+" ")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ipAddress') + ' *',"label-for":"ipAddress"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"ipAddress","state":_vm.ipAddressState},model:{value:(_vm.ipAddress),callback:function ($$v) {_vm.ipAddress=$$v},expression:"ipAddress"}})],1)],1),_c('b-col',{class:{ disabled: _vm.selectedPlc !== 0 },attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.s7.rackNumber') + ' *',"label-for":"rackNumber"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"rackNumber","min":"0","max":"1000000","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.rackNumber = 0;
                                    } else if (value > 1000000) {
                                        this.rackNumber = 1000000;
                                    } else if (value < 0) {
                                        this.rackNumber = 0;
                                    }
                                }},model:{value:(_vm.rackNumber),callback:function ($$v) {_vm.rackNumber=$$v},expression:"rackNumber"}})],1)],1),_c('b-col',{class:{ disabled: _vm.selectedPlc !== 0 },attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.s7.rackSlot') + ' *',"label-for":"rackSlot"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"rackSlot","min":"0","max":"1000000","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.rackSlot = 0;
                                    } else if (value > 1000000) {
                                        this.rackSlot = 1000000;
                                    } else if (value < 0) {
                                        this.rackSlot = 0;
                                    }
                                }},model:{value:(_vm.rackSlot),callback:function ($$v) {_vm.rackSlot=$$v},expression:"rackSlot"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ConnectionType') + ' *',"label-for":"connectionType"}},[_c('b-form-select',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"connectionType","options":_vm.availableConnectionTypes},model:{value:(_vm.selectedConnectionType),callback:function ($$v) {_vm.selectedConnectionType=$$v},expression:"selectedConnectionType"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.s7.localTSAP') + ' *',"label-for":"localTSAP"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"localTSAP","state":_vm.localTSAPState},model:{value:(_vm.localTSAP),callback:function ($$v) {_vm.localTSAP=$$v},expression:"localTSAP"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.s7.remoteTSAP') + ' *',"label-for":"remoteTSAP"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"remoteTSAP","state":_vm.remoteTSAPState},model:{value:(_vm.remoteTSAP),callback:function ($$v) {_vm.remoteTSAP=$$v},expression:"remoteTSAP"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Refresh rate') + ' * (ms)',"label-for":"pollingPeriod"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"pollingPeriod","min":"200","max":"60000","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.pollingPeriod = 500;
                                    } else if (value > 60000) {
                                        this.pollingPeriod = 60000;
                                    } else if (value < 200) {
                                        this.pollingPeriod = 200;
                                    }
                                }},model:{value:(_vm.pollingPeriod),callback:function ($$v) {_vm.pollingPeriod=$$v},expression:"pollingPeriod"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Timeout') + ' * (ms)',"label-for":"timeout"}},[_c('b-form-input',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"timeout","min":"0","max":"600000","type":"number"},on:{"change":(value) => {
                                    if (value == null || value == '') {
                                        this.timeout = 2000;
                                    } else if (value > 600000) {
                                        this.timeout = 600000;
                                    } else if (value < 0) {
                                        this.timeout = 0;
                                    }
                                }},model:{value:(_vm.timeout),callback:function ($$v) {_vm.timeout=$$v},expression:"timeout"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{class:{ disabled: !this.$store.state.auth.user.permissions.PermissionRead },on:{"click":_vm.onCheckConnection}},[_vm._v(" "+_vm._s(_vm.$t('equipment.opcua.checkConnection'))+" "),(_vm.loadingConnectionCheck)?_c('font-awesome-icon',{staticClass:"fa-lg fa-spin-custom",attrs:{"icon":"fas fa-spinner-third"}}):_vm._e()],1)],1)],1)],1)]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Variables'))+" "),_c('b-badge',{attrs:{"pill":"","variant":"info"}},[_vm._v(_vm._s(_vm.groups .map((gr) => gr.Items.length) .reduce(function (a, b) { return a + b; }, 0)))])]},proxy:true}])},[_c('ItemConfiguration',{ref:"itemConfiguration",attrs:{"columns":_vm.itemColumns,"groups":_vm.groups,"separateGroupConfig":false,"groupConfiguration":_vm.groupConfiguration,"readonly":!this.$store.state.auth.user.permissions.PermissionWrite,"updateMode":_vm.updateMode,"RefreshVarCounter":_vm.refreshVariablesCounter}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }