<template>
    <div class="tabContent">
        <b-navbar class="shadow" v-if="showToolbar">
            <b-container fluid class="navbar-container">
                <b-navbar-nav>
                    <!-- Save -->
                    <b-nav-item v-on:click="onSaveAction" :title="$t('Save')" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }">
                        <font-awesome-layers class="top-menu-icon">
                            <font-awesome-icon icon="fas fa-floppy-disk" class="mr-1 fa-lg" />
                            <font-awesome-icon v-if="saving" icon="sync" class="fa-xs fa-spin-custom" transform="down-10.0 right-10.0" color="#c8b900" />
                        </font-awesome-layers>
                    </b-nav-item>
                    <!-- Cancel -->
                    <b-nav-item v-on:click="onCancel" :title="$t('Cancel')">
                        <font-awesome-icon icon="far fa-arrow-right-from-bracket" class="top-menu-icon mr-1 fa-lg" />
                    </b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="font-bold"> {{ this.action.Equipment }} - {{ this.action.Group }} > {{ this.action.Name }} </b-navbar-nav>
                <b-navbar-nav>
                    <!-- YEEELP -->
                    <b-nav-item v-on:click="displayHelp" :title="$t('Help')">
                        <font-awesome-icon icon="fas fa-square-question" class="top-menu-icon mr-1 fa-lg" />
                    </b-nav-item>
                </b-navbar-nav>
            </b-container>
        </b-navbar>
        <b-form class="mx-3">
            <b-row>
                <b-col>
                    <b-form-group :label="$t('actions.wizard.description')" label-for="description">
                        <b-form-input v-model="action.Description" id="description" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-checkbox switch v-model="action.Trace" style="margin-top: 35px" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }">{{ $t('actions.wizard.trace') }}</b-form-checkbox>
                </b-col>
                <b-col>
                    <b-form-checkbox switch v-model="action.Active" style="margin-top: 35px" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }">{{ $t('actions.wizard.active') }}</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-tabs pills card h-100 d-flex flex-column class="tabGlobal1 classexemple2" name="nameTest">
                        <!-- Trigger configuration -->
                        <!-- <b-tab :lazy="triggerTabLazy" @click="triggerTabLazy = false"> -->
                        <b-tab>
                            <template #title>
                                {{ $t('Trigger') }}
                                <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidTriggerState" />
                            </template>

                            <b-card no-body>
                                <b-form class="triggerForm">
                                    <b-row>
                                        <b-col>
                                            <b-form-group :label="$t('Trigger Type')" label-for="triggerMode">
                                                <b-form-select
                                                    :disabled="false"
                                                    v-model="triggerMode"
                                                    :options="triggerModes"
                                                    :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }"
                                                    @input="setTriggerType(triggerMode)"
                                                    :validation-rules="[{ type: 'required' }]"
                                                    :state="triggerModeState"
                                                >
                                                </b-form-select>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group :label="$t('Activation delay')" label-for="SleepTime">
                                                <b-form-input
                                                    v-model="action.DeclencheurConfiguration.SleepTime"
                                                    type="number"
                                                    min="0"
                                                    max="60000"
                                                    @change="
                                                        (value) => {
                                                            if (value == null || value == '') {
                                                                action.DeclencheurConfiguration.SleepTime = 0;
                                                            } else if (value > 60000) {
                                                                action.DeclencheurConfiguration.SleepTime = 60000;
                                                            } else if (value < 0) {
                                                                action.DeclencheurConfiguration.SleepTime = 0;
                                                            }
                                                        }
                                                    "
                                                    :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <!-- Place here the list of trigger configuration components with a v-if for every one to make it correspond to its specific configuration -->
                                    <NotificationAdvanced v-if="triggerMode == 'Productys.ProdCom.Structure.Classes.VarDeclencheurEvolue'" :equipment="action.Equipment" :action="action" ref="triggerConfig" :propProject="project" />
                                    <UnitaryNotification v-if="triggerMode == 'Productys.ProdCom.Structure.Classes.VarDeclencheur' && action.Equipment != ''" :equipment="action.Equipment" :action="action" ref="triggerConfig" :propProject="project" />
                                    <Cyclic v-if="triggerMode == 'Productys.ProdCom.Structure.Classes.TimerDeclencheur'" :equipment="action.Equipment" :action="action" ref="triggerConfig" :propProject="project" />
                                    <Planning v-if="triggerMode == 'Productys.ProdCom.Structure.Classes.PlanifDec'" :equipment="action.Equipment" :action="action" ref="triggerConfig" :propProject="project" />
                                    <Sequence v-if="triggerMode == 'Productys.ProdCom.Structure.Classes.DecDeclencheur'" :equipment="action.Equipment" :action="action" ref="triggerConfig" :propProject="project" />
                                    <StartStop v-if="triggerMode == 'Productys.ProdCom.Structure.Classes.DemarrageDeclencheur'" :equipment="action.Equipment" :action="action" ref="triggerConfig" :propProject="project" />
                                </b-form>
                            </b-card>
                        </b-tab>
                        <!-- Activation criterias -->
                        <b-tab>
                            <!-- <b-tab :lazy="activationTabLazy" @click="activationTabLazy = false" v-if="action.Equipment != ''"> -->
                            <template #title
                                >{{ $t('Activation') }}
                                <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidActivationState" />
                            </template>
                            <b-card no-body>
                                <b-form class="triggerForm">
                                    <b-row>
                                        <b-col cols="3">
                                            <b-form-group :label="$t('Criteria count')" label-for="criteriaCount">
                                                <b-form-input v-model="criteriaCount" type="number" :min="0" :max="6" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-tabs pills card h-100 d-flex flex-column>
                                        <b-tab v-for="crit in criterias" :key="crit.id" :active="currentTab === crit.id" :disabled="crit.id >= criteriaCount && crit.id > 0">
                                            <template #title
                                                >{{ $t('Criteria') }} {{ crit.id + 1 }}
                                                <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-show="invalidTabActionState[crit.id] && criteriaCount > crit.id" />
                                            </template>
                                            <UnitaryNotification v-show="criteriaCount > 0" v-if="action.Equipment != ''" :equipment="action.Equipment" :action="crit" :propProject="project" :ref="'activation_' + crit.id" :onlyCriteria="true" />
                                        </b-tab>
                                    </b-tabs>
                                </b-form>
                            </b-card>
                        </b-tab>
                        <!-- Any specific action configuration -->
                        <AcquisitionEquipement v-if="this.action.ActionType == 'AcquisitionEquipement'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <CarteXr v-if="this.action.ActionType == 'CarteXr'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <Comptage v-else-if="this.action.ActionType == 'Comptage'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <ConsignationEquipement v-else-if="this.action.ActionType == 'ConsignationEquipement'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <ConsignationSourceEquipement v-else-if="this.action.ActionType == 'ConsignationSourceEquipement'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <EnvoiMail v-else-if="this.action.ActionType == 'Mail'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <Executable v-else-if="this.action.ActionType == 'Executable'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <EchangeEquipement v-else-if="this.action.ActionType == 'EchangeEquipement'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <Operations v-else-if="this.action.ActionType == 'Operations'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <Query v-else-if="this.action.ActionType == 'Query'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <Statistique v-else-if="this.action.ActionType == 'Statistique'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                        <UpdateEquipment v-else-if="this.action.ActionType == 'UpdateEquipment'" :action="action" ref="actionConfigTabs" :propProject="this.project" />
                    </b-tabs>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import UnitaryNotification from '@/components/configuration/ActionsTriggers/UnitaryNotification.vue';
import NotificationAdvanced from '@/components/configuration/ActionsTriggers/NotificationAdvanced.vue';
import Cyclic from '@/components/configuration/ActionsTriggers/Cyclic.vue';
import Planning from '@/components/configuration/ActionsTriggers/Planning.vue';
import Sequence from '@/components/configuration/ActionsTriggers/Sequence.vue';
import StartStop from '@/components/configuration/ActionsTriggers/StartStop.vue';
import AcquisitionEquipement from '@/components/configuration/Actions/AcquisitionEquipement.vue';
import CarteXr from '@/components/configuration/Actions/CarteXr.vue';
import Comptage from '@/components/configuration/Actions/Comptage.vue';
import ConsignationEquipement from '@/components/configuration/Actions/ConsignationEquipement.vue';
import EnvoiMail from '@/components/configuration/Actions/EnvoiMail.vue';
import Executable from '@/components/configuration/Actions/Executable.vue';
import EchangeEquipement from '@/components/configuration/Actions/EchangeEquipement.vue';
import ConsignationSourceEquipement from '@/components/configuration/Actions/ConsignationSourceEquipement.vue';
import Operations from '@/components/configuration/Actions/Operations.vue';
import Query from '@/components/configuration/Actions/Query.vue';
import Statistique from '@/components/configuration/Actions/Statistique.vue';
import UpdateEquipment from '@/components/configuration/Actions/UpdateEquipment.vue';
import ProdComActionService from '@/services/prodcom.actions.service.js';
import ToastAlert from '@/utils/ToastAlert';
import constants from '@/utils/constants';
import { uuid } from 'vue-uuid';
import { trigger } from 'devextreme/events';

// import Help from '@/components/Help.vue';
/**
 * Global action configuration view. From here we call
 * the different configuration panels depending on the action type.
 */
export default {
    name: 'Action',
    components: {
        UnitaryNotification,
        Cyclic,
        Planning,
        StartStop,
        Sequence,
        NotificationAdvanced,
        AcquisitionEquipement,
        CarteXr,
        EchangeEquipement,
        EnvoiMail,
        Executable,
        Comptage,
        ConsignationEquipement,
        ConsignationSourceEquipement,
        Operations,
        Query,
        Statistique,
        UpdateEquipment,
    },
    props: {
        action: Object,
        propProject: String,
        showToolbar: Boolean,
        closeTab: Function,
    },
    watch: {
        propProject(newVal, oldVal) {
            this.project = newVal;
        },
        criteriaCount(newVal, oldVal) {
            if (newVal > 0) this.currentTab = newVal - 1;
        },
    },
    computed: {
        invalidTriggerState() {
            return this.invalidTriggerDependancies;
        },
        invalidActivationState() {
            return this.invalidActivationDependancies;
        },
        triggerModeState() {
            return this.triggerMode != null ? null : false;
        },
    },
    async mounted() {
        if (!this.$store.state.auth.user.permissions.PermissionRead) return;
        // Get the list of trigger types
        const result = await ProdComActionService.getTriggerTypes();
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.triggerModes = [];
            for (var trigger of result.ret) {
                this.triggerModes.push({ value: trigger, text: this.$t(trigger) });
            }
            if (!this.triggerMode) {
                this.triggerMode = this.triggerModes[0].value;
            }
        }

        // Create a default configuration if none has been set. (cyclic, 10 seconds interval, rounded)
        if (this.action.DeclencheurType == null) {
            this.action.DeclencheurType = 'Productys.ProdCom.Structure.Classes.TimerDeclencheur';
            this.action.DeclencheurConfiguration.Interval = 10;
            this.action.DeclencheurConfiguration.IsRounded = true;
        }
        this.criteriaCount = this.action?.Activation?.length ?? 0;

        let i = 0;
        if (this.action?.Activation == null) return;
        for (const link of this.action.Activation) {
            // this.criterias[i].Link = link.Link;
            this.criterias[i].DeclencheurConfiguration = link;
            await this.$refs['activation_' + i][0]?.loadConfig(link);
            i++;
        }
    },
    data() {
        return {
            invalidTabActionState: [false, false, false, false, false, false],
            triggerTabLazy: true,
            activationTabLazy: true,
            triggerModes: [],
            triggerMode: this.action.DeclencheurType,
            editDescription: false,
            project: this.propProject,
            criteriaCount: 0,
            currentTab: 0,
            criterias: [
                { id: 0, Link: 0, DeclencheurConfiguration: {} },
                { id: 1, Link: 0, DeclencheurConfiguration: {} },
                { id: 2, Link: 0, DeclencheurConfiguration: {} },
                { id: 3, Link: 0, DeclencheurConfiguration: {} },
                { id: 4, Link: 0, DeclencheurConfiguration: {} },
                { id: 5, Link: 0, DeclencheurConfiguration: {} },
            ],
            saving: false,
            DeclencheurType: 'Productys.ProdCom.Structure.Classes.TimerDeclencheur',
            invalidDependancies: false,
            invalidTriggerDependancies: false,
            invalidActivationDependancies: false,
        };
    },
    methods: {
        setTriggerType(triggerType) {
            this.triggerMode = triggerType;
            this.$set(this.action, 'DeclencheurType', triggerType);
        },
        async clickEditDescription() {
            this.editDescription = !this.editDescription;
        },
        async validate() {
            this.invalidDependancies = false;
            this.invalidTriggerDependancies = false;
            if (this.$refs['actionConfigTabs']?.validate != null && this.$refs['triggerConfig']?.validate != null) {
                const actionData = await this.$refs['actionConfigTabs'].validate();
                const triggerConfig = await this.$refs['triggerConfig'].validate();
                if (actionData == null) {
                    this.invalidDependancies = true;
                }
                if (triggerConfig == null) {
                    this.invalidTriggerDependancies = true;
                } else {
                    triggerConfig.SleepTime = this.action.DeclencheurConfiguration.SleepTime;
                }

                const activ = await this.validateActivations();

                if (activ.findIndex((act) => act == null) != -1) {
                    this.invalidActivationDependancies = true;
                }

                if (this.invalidDependancies || this.invalidTriggerDependancies || this.invalidActivationDependancies) return null;

                // Validate the list
                var triggerData = {
                    Equipment: this.action.Equipment,
                    ProjectId: this.project,
                    Description: this.action.Description,
                    Edge: 'FrontMontant',
                    TriggerType: this.action.DeclencheurType,
                    TriggerConfiguration: triggerConfig,
                    ActionType: this.action.ActionType,
                    ActionData: actionData,
                    Active: this.action.Active,
                    Activation: activ,
                    Trace: this.action.Trace,
                };
                return triggerData;
            }
            return null;
        },
        /**
         * Validates and returns the list of activation criterias
         */
        async validateActivations() {
            try {
                this.invalidActivationDependancies = false;
                this.invalidTabActionState = [false, false, false, false, false, false];
                let activationArray = [];
                for (const activ of this.criterias) {
                    if (activ.id >= this.criteriaCount) break;
                    const critEl = this.$refs['activation_' + activ.id][0];
                    if (critEl?.validate != null) {
                        let result = await critEl.validate();
                        if (result == null) {
                            this.$set(this.invalidTabActionState, activ.id, true);
                            activationArray.push(null);
                        } else {
                            this.$set(this.invalidTabActionState, activ.id, false);
                            // result.Link = activ.Link;
                            activationArray.push(result);
                        }
                    }
                }
                return activationArray;
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        /**
         * Updates the displayed action.
         */
        async onSaveAction() {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.saving = true;
            try {
                let result = await this.validate();
                if (result == null) {
                    this.saving = false;
                    return;
                }
                result.Group = this.action.Group;
                result.Name = this.action.Name;
                let response = await ProdComActionService.updateAction(result.ActionType, result, this.action.project, this.action.Equipment, this.action.Group, this.action.Name);
                if (response.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(response.ret, response.retParams), 'warning'));
                else {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('Saved action'), 'info'));
                    let res = await ProdComActionService.getActionDetails(this.action.Name, this.action.Group, this.action.Equipment, this.project);
                    if (response.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(response.ret, response.retParams), 'warning'));
                    let result = this.$store.state.tabs.findIndex((act) => act.type == constants.TAB_TYPE_ACTION && act.Name == res.ret.Name && act.Equipment == res.ret.Equipment);
                    if (result == -1) {
                        res.ret.type = constants.TAB_TYPE_ACTION;
                        res.ret.uuid = uuid.v4();
                        this.$store.state.tabs.push(res.ret);
                    } else {
                        res.ret.uuid = this.$store.state.tabs[result].uuid;
                        res.ret.project = this.$store.state.tabs[result].project;
                        res.ret.type = this.$store.state.tabs[result].type;
                        this.$store.state.tabs[result] = res.ret;
                    }
                }
            } catch (error) {
                console.log(error);
            }
            this.saving = false;
        },
        async onCancel() {
            if (this.closeTab != null) {
                await this.closeTab();
            }
        },
        async displayHelp() {
            this.$store.commit('displayHelp', this.$t('help.actions'));
            this.$bvModal.show('bv-modal-help');
        },
    },
};
</script>

<style lang="scss">
.triggerForm {
    margin: 1.25rem;
}

.tabGlobal1 {
    padding: 0;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

div[name='nameTest'] .card-header {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

div[name='nameTest'] .tab-content {
    height: 100% !important;
    flex: 1 !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    overflow-y: auto !important;
}
</style>