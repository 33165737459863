<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%" :class="{ disabled: loadingConnectionCheck }">
        <!-- Connexion configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidState" />
                </span>
            </template>

            <div>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipment.EluCloud.address') + ' *'" label-for="address">
                            <b-form-input
                                id="address"
                                v-model="address"
                                :state="addressState"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.address = 'localhost';
                                        }
                                        this.machinesLoadedState = false;
                                    }
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipment.EluCloud.port') + ' *'" label-for="port">
                            <b-form-input
                                id="port"
                                v-model="port"
                                type="number"
                                min="1"
                                max="65535"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.port = 31750;
                                        } else if (value > 65535) {
                                            this.port = 65535;
                                        } else if (value < 1) {
                                            this.port = 1;
                                        }
                                        this.machinesLoadedState = false;
                                    }
                                "
                                :state="portState"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipment.EluCloud.username')" label-for="user">
                            <b-form-input
                                id="user"
                                v-model="username"
                                @change="
                                    (value) => {
                                        this.machinesLoadedState = false;
                                    }
                                "
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipment.EluCloud.token')" label-for="token">
                            <b-form-input
                                id="token"
                                v-model="token"
                                @change="
                                    (value) => {
                                        this.machinesLoadedState = false;
                                    }
                                "
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipment.EluCloud.RefreshDelay') + ' *'" label-for="refreshDelay">
                            <b-form-input
                                id="refreshDelay"
                                v-model="refreshDelay"
                                min="500"
                                max="60000"
                                type="number"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.refreshDelay = 2000;
                                        } else if (value > 60000) {
                                            this.refreshDelay = 60000;
                                        } else if (value < 500) {
                                            this.refreshDelay = 500;
                                        }
                                    }
                                "
                                :state="refreshDelayState"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('Machines') + ' *'" label-for="machinesParameter">
                            <b-form-select id="machinesParameter" class="col-10" :state="machinesLoadedState" v-model="ChoosenMachine" :options="availableMachines" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite || !machinesLoadedState }" />
                            <b-button class="col-1 ml-1" id="buttonSearch" @click="onBrowseMachines" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionRead, 'btn-danger': !machinesLoadedState, 'btn-success': machinesLoadedState }">
                                <font-awesome-icon v-if="!loadingBrowseMachineCheck" icon="search" class="cursor-pointer" />
                                <font-awesome-icon v-if="loadingBrowseMachineCheck" icon="fas fa-spinner-third" class="fa-lg fa-spin-custom" />
                            </b-button>
                        </b-form-group>
                    </b-col>
                    <b-col cols="1"> </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-button @click="onCheckConnection" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionRead }">
                            {{ $t('equipment.opcua.checkConnection') }}
                            <font-awesome-icon v-if="loadingConnectionCheck" icon="fas fa-spinner-third" class="fa-lg fa-spin-custom" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ToastAlert from '@/utils/ToastAlert';
import { uuid } from 'vue-uuid';

export default {
    name: 'ConfigEluCloud',
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    async mounted() {},
    data() {
        return {
            machinesLoadedState: false, // allows us to disable the select input if we don't browse the machines
            ChoosenMachine: this.equipment?.ChoosenMachine == undefined || this.equipment?.ChoosenMachine == 'All' ? this.$t('AllMachines') : this.equipment?.ChoosenMachine,
            availableMachines: this.equipment?.ChoosenMachine == undefined || this.equipment?.ChoosenMachine == 'All' ? [this.$t('AllMachines')] : [this.$t('AllMachines'), this.equipment?.ChoosenMachine],
            loadingBrowseMachineCheck: false,
            loadingConnectionCheck: false,
            address: this.equipment?.Address ?? 'localhost',
            port: this.equipment?.Port ?? '31750',
            username: this.equipment?.username ?? '',
            token: this.equipment?.token ?? '',
            refreshDelay: this?.equipment?.refreshDelay ?? 2000,
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name') },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type') },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address') },
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, refreshRate: 500, Items: [] }],
            groupConfiguration: {
                rows: [
                    {
                        columns: [{ text: this.$t('Refresh rate'), variable: 'RefreshRate', inputType: 'number', cols: '6', min: '100', max: '99999', defaultValue: 500 }],
                    },
                ],
            },
        };
    },
    methods: {
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.address = this.address;
            this.equipment.port = this.port;
            this.equipment.token = this.token;
            this.equipment.username = this.username;
            this.equipment.refreshDelay = this.refreshDelay;
            this.equipment.ChoosenMachine = this.$t('AllMachines') === this.ChoosenMachine ? 'All' : this.ChoosenMachine;
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {},
        /*
        Get the list of machines available in the elucloud server
        */
        async onBrowseMachines() {
            this.loadingBrowseMachineCheck = true;
            const result = await ProdComEquipmentService.EluCloudBrowseMachines(this.connectionParameters());
            if (result.ret.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.EluCloud.failedToConnect'), 'warning'));
                this.machinesLoadedState = false;
            } else {
                let nbMachines = result.ret.res.length;
                this.availableMachines = [this.$t('AllMachines')].concat(result.ret.res);
                this.$EventBus.$emit('show-toast', new ToastAlert(nbMachines + ' ' + this.$t('equipment.EluCloud.machinesFound'), 'success', this.$t('ConnectionSuccess')));
                this.machinesLoadedState = true;
            }
            this.loadingBrowseMachineCheck = false;
        },
        async saveEquipmentCommunication() {},
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        async onCheckConnection(e) {
            this.machinesLoadedState = false;
            this.loadingConnectionCheck = true;
            const result = await ProdComEquipmentService.EluCloudCheckConnection(this.connectionParameters());
            if (result.ret.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.EluCloud.failedToConnect'), 'warning'));
            } else {
                if (!result.ret.Connected) {
                    if (result.ret.ConnectionFailedMessage !== '') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.EluCloud.failedToConnect'), 'warning'));
                    }
                } else {
                    await this.onBrowseMachines();
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.EluCloud.connectionSuccess'), 'success', this.$t('ConnectionSuccess')));
                }
            }
            this.loadingConnectionCheck = false;
        },
        connectionParameters() {
            return {
                address: this.address,
                port: this.port,
                name: this.newEquipmentName,
                token: this.token,
                username: this.username,
                refreshDelay: this.refreshDelay,
                ChoosenMachine: this.$t('AllMachines') === this.ChoosenMachine ? 'All' : this.ChoosenMachine,
            };
        },
        /**
         * Returns the correctly formated object of connection parameters
         */
        validate() {
            if (this.invalidState) return null;
            //we are forced to add that to avoid some issues with different translations
            return this.connectionParameters();
        },
        onItemInserting(e, group) {},
        /**
         * Called when browsing for items in configuration. Returns the list of available items
         */
        async onItemBrowse(e) {},
    },
    computed: {
        invalidState() {
            return this.addressState === false || this.portState === false || !this.machinesLoadedState || this.refreshDelayState === false;
        },
        addressState() {
            return this.address == null || this.address == '' ? false : null;
        },
        refreshDelayState() {
            return this.refreshDelay == null || this.refreshDelay == '' ? false : null;
        },
        portState() {
            return this.port == null || this.port == '' ? false : null;
        },
    },
};
</script>

<style scoped lang="scss">
.icon-check {
    transition: all 0.3s;
}
.icon-check:hover {
    transform: scale(1.3);
    transition: all 0.3s;
    cursor: pointer;
}
#username[readonly],
#password[readonly] {
    opacity: 0.5;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: default !important;
    pointer-events: none !important;
    color: #dedede !important;
}
</style>