<template>
    <div>
        <b-tab>
            <template #title>{{ $t('actions.query.title') }}</template>
            <b-card v-if="equipmentHasDataSource">
                <b-form>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.query.query') + ' *'" label-for="query">
                                <b-form-select id="query" v-model="Query" :options="availableQueries" v-on:change="queryChanged" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <DxDataGrid
                            @editing-start="onEditingStart"
                            @cell-prepared="onCellPrepared"
                            :dataSource="Parameters"
                            :allow-column-reordering="true"
                            :repaint-changes-only="true"
                            :show-borders="false"
                            :selection="{ mode: 'single' }"
                            :columnAutoWidth="true"
                            show-scrollbar="onHover"
                            width="100%"
                            :allow-column-resizing="true"
                        >
                            <DxEditing :allow-updating="$store.state.auth.user.permissions.PermissionWrite" :allow-adding="false" :allow-deleting="false" :use-icons="true" mode="cell" />
                            <DxColumn data-field="Name" width="150" data-type="string" :allow-editing="false" :caption="$t('Name')" alignment="left" />
                            <DxColumn data-field="DbType" width="140" data-type="string" :allow-editing="false" :caption="$t('DbType')" alignment="left" />
                            <DxColumn data-field="SimpleTypeName" width="140" data-type="string" :allow-editing="false" :caption="$t('Type')" alignment="left" />
                            <DxColumn data-field="Direction" width="100" data-type="string" :allow-editing="false" :caption="$t('Direction')" alignment="left">
                                <DxLookup :data-source="availableDirections" value-expr="value" display-expr="text" />
                            </DxColumn>
                            <DxColumn data-field="Group" width="150" :set-cell-value="getItems" data-type="string" :allow-editing="true" :caption="$t('Group')" alignment="left">
                                <DxLookup :data-source="availableGroups" />
                            </DxColumn>
                            <DxColumn data-field="Variable" width="150" data-type="string" :allow-editing="true" :caption="$t('Variable')" alignment="left">
                                <DxLookup :data-source="groupItems" />
                            </DxColumn>
                            <DxColumn data-field="HasDefaultValue" data-type="boolean" :allow-editing="false" :caption="$t('HasDefaultValue')" alignment="center" />
                            <DxColumn data-field="DefaultValueReal" data-type="string" :allow-editing="false" :caption="$t('DefaultValueReal')" alignment="left" />
                            <DxColumn data-field="DefaultValueFromDefinition" data-type="boolean" :allow-editing="false" :caption="$t('DefaultValueFromDefinition')" alignment="center" />
                        </DxDataGrid>
                    </b-row>
                </b-form>
            </b-card>
            <b-card v-else>
                <span>This action requires that the equipment has a data source configured. Please configure the equipment data source before editing.</span>
            </b-card>
        </b-tab>
    </div>
</template>

<script>
import ProdComDataSourceService from '@/services/prodcom.datasources.service.js';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ToastAlert from '@/utils/ToastAlert';
import { DxDataGrid, DxColumn, DxToolbar, DxItem, DxLookup, DxEditing } from 'devextreme-vue/data-grid';
export default {
    name: 'Query',
    props: {
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxToolbar,
        DxItem,
        DxLookup,
    },
    data() {
        return {
            Query: '',
            Parameters: [],
            availableQueries: [],
            equipmentHasDataSource: false,
            availableDirections: [
                { text: 'Input', value: 1 },
                { text: 'Output', value: 2 },
                { text: 'InputOutput', value: 3 },
                { text: 'ReturnValue', value: 6 },
            ],
            availableGroups: [],
            availableItems: [],
            groupItems: [],
        };
    },
    async mounted() {
        // Check if equipment has a data source
        var result = await ProdComDataSourceService.hasDataSource(this.action.Equipment, this.propProject);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.equipmentHasDataSource = result.ret;
        }
        // Don't do anything more if the equipment doesn't have a data source (avoid useless queries)
        if (!this.equipmentHasDataSource) return;
        // Get list of queries
        result = await ProdComDataSourceService.getQueries(this.action.Equipment, this.propProject);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.availableQueries = [];
            for (const query of result.ret) {
                this.availableQueries.push({
                    text: query,
                    value: { query: query.replace(')', '').split('(')[0], specificName: query.replace(')', '').split('(')[1] },
                });
            }
        }
        if (this.action.ActionConfiguration?.Query == null) {
            this.Query = this.availableQueries[0].value;
        } else {
            if (this.action.ActionConfiguration?.SpecificName == null || this.action.ActionConfiguration?.SpecificName == '' || this.action.ActionConfiguration?.SpecificName == ')') {
                this.Query = this.availableQueries.find((q) => q.value.query == this.action.ActionConfiguration?.Query).value;
            } else {
                this.Query = { query: this.action.ActionConfiguration?.Query, specificName: this.action.ActionConfiguration?.SpecificName };
            }
        }
        await this.queryChanged();
        // Get equipment group list
        result = await ProdComEquipmentService.getGroups(this.action.Equipment, this.propProject);
        if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
        else {
            this.availableGroups = result.ret.List;
        }
        // get all equipment's variable (could not get the DevExtreme component to dynamically query this on value change...)
        this.availableItems = [];
        for (var grp of this.availableGroups) {
            result = await ProdComEquipmentService.getItems(this.action.Equipment, grp, this.propProject);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                for (const [key, val] of Object.entries(result.ret.List)) {
                    this.availableItems.push({ group: grp, item: val.Name, value: val });
                }
            }
        }
        // Set parameters
        if (this.action.ActionConfiguration?.Parameters != null) {
            this.Parameters = this.action.ActionConfiguration?.Parameters;
        } else {
            await this.queryChanged();
        }
        if (this.action.ActionConfiguration != null) {
        }
    },
    methods: {
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action.
         */
        async validate() {
            var result = {
                Parameters: this.Parameters,
                Query: this.Query.query,
                SpecificName: this.Query.specificName,
            };
            return result;
        },
        async queryChanged(e) {
            // Get query parameters
            const result = await ProdComDataSourceService.getQueryParameters(this.action.Equipment, this.propProject, this.Query?.query + '(' + this.Query?.specificName + ')');
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                this.Parameters = result.ret;
            }
        },
        getItems(newData, value, currentRowData) {
            newData.Group = value;
            if (value == null) {
                newData.Variable = null;
                return [];
            }
            this.groupItems = this.availableItems.filter((it) => it.group === value).map((it) => it.item);
            if (this.groupItems.length > 0) newData.Variable = this.groupItems[0];
        },
        onEditingStart(e) {
            this.groupItems = this.availableItems.filter((it) => it.group === e.data.Group).map((it) => it.item);
            if (this.groupItems.length > 0 && e.data.Variable == null) e.data.Variable = this.groupItems[0];
        },
        onCellPrepared(e) {
            if (e.rowType === 'data' && e.column.dataField === 'Direction') {
                if (e.data.Direction == 1) e.cellElement.style.backgroundColor = this.$store.state.theme == 'dark' ? '#444444' : '#EEEEEE';
                if (e.data.Direction == 2) e.cellElement.style.backgroundColor = this.$store.state.theme == 'dark' ? '#666666' : '#CCCCCC';
                if (e.data.Direction == 3) e.cellElement.style.backgroundColor = this.$store.state.theme == 'dark' ? '#888888' : '#AAAAAA';
                if (e.data.Direction == 6) e.cellElement.style.backgroundColor = this.$store.state.theme == 'dark' ? '#AAAAAA' : '#888888';
            } else if (e.rowType === 'data' && (e.column.dataField === 'Group' || e.column.dataField === 'Variable')) {
                e.cellElement.style.backgroundColor = this.$store.state.theme == 'dark' ? '#222222' : '#FFFFFF';
            }
        },
    },
};
</script>

<style>
</style>