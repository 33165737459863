<template>
    <b-tabs pills card h-100 d-flex flex-column style="width: 100%" :class="{ disabled: loadingConnectionCheck }">
        <!-- Connection configuration -->
        <b-tab>
            <template #title>
                <span class="tabHeaderText">
                    {{ $t('Connection') }}
                    <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidState" />
                </span>
            </template>
            <div>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('Type') + ' *'" label-for="selectedConnectionType">
                            <b-form-select id="selectedConnectionType" v-model="selectedConnectionType" :options="availableConnectionTypes" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="selectedConnectionType == 0">
                        <b-form-group :label="$t('equipment.opcua.LDSAddress') + ' *'" label-for="ldsAddress">
                            <b-row>
                                <b-col cols="11">
                                    <b-form-input id="ldsAddress" v-model="ldsAddress" :state="ldsAddressState" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                                </b-col>
                                <b-col cols="1">
                                    <font-awesome-icon v-if="!loadingLds" icon="fas fa-redo" class="fa-lg cursor-pointer" :title="$t('equipment.opcua.checkConnection')" v-on:click="getLdsInformation" />
                                    <font-awesome-icon v-if="loadingLds" icon="fas fa-spinner-third" class="fa-lg fa-spin-custom" />
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" v-else-if="selectedConnectionType == 1">
                        <b-form-group :label="$t('equipment.opcua.URL') + ' *'" label-for="manualUrl">
                            <b-form-input id="manualUrl" v-model="manualUrl" :state="manualUrlState" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <div v-if="selectedConnectionType == 0">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('equipment.opcua.server') + ' *'" label-for="server" :class="{ disabled: !ldsConnectionVerified || !this.$store.state.auth.user.permissions.PermissionWrite }">
                                <b-form-select id="server" v-model="selectedLdsServer" :options="ldsServerUrls" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('equipment.opcua.endpoint') + ' *'" label-for="endpoint" :class="{ disabled: !ldsConnectionVerified || !this.$store.state.auth.user.permissions.PermissionWrite }">
                                <b-form-select id="endpoint" v-model="selectedLdsEndpoint" :options="ldsServerEndpoints" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else-if="selectedConnectionType == 1">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('equipment.opcua.securityMode') + ' *'" label-for="securityMode">
                                <b-form-select id="securityMode" v-model="selectedSecurityMode" :options="availableSecurityModes" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('equipment.opcua.securityPolicy') + ' *'" label-for="securityPolicy">
                                <b-form-select id="securityPolicy" v-model="selectedSecurityPolicy" :options="availableSecurityPolicies" :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite }" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <hr color="grey" />
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('equipment.opcua.minKeySize') + ' *'" label-for="minKeySize">
                            <b-form-input
                                id="minKeySize"
                                v-model="minKeySize"
                                type="number"
                                :min="1024"
                                :max="65535"
                                @change="
                                    (value) => {
                                        if (value == null || value == '') {
                                            this.minKeySize = 2048;
                                        } else if (value > 65535) {
                                            this.minKeySize = 65535;
                                        } else if (value < 1024) {
                                            this.minKeySize = 1024;
                                        }
                                    }
                                "
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite || this.selectedConnectionType == 0 || (this.$store.state.auth.user.permissions.PermissionWrite && selectedSecurityMode == 1) }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group :label="$t('User name')" label-for="username">
                            <b-form-input
                                id="username"
                                autocomplete="false"
                                v-model="username"
                                :readonly="selectedSecurityMode == 1"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite || (this.$store.state.auth.user.permissions.PermissionWrite && selectedSecurityMode == 1) }"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t('Password')" label-for="password">
                            <b-form-input
                                id="password"
                                autocomplete="false"
                                v-model="password"
                                type="password"
                                :readonly="selectedSecurityMode == 1"
                                :class="{ disabled: !this.$store.state.auth.user.permissions.PermissionWrite || (this.$store.state.auth.user.permissions.PermissionWrite && selectedSecurityMode == 1) }"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-button @click="onCheckConnection" :class="{ disabled: (selectedConnectionType == 0 && !ldsConnectionVerified) || !this.$store.state.auth.user.permissions.PermissionRead }">
                            {{ $t('equipment.opcua.checkConnection') }}
                            <font-awesome-icon v-if="loadingConnectionCheck" icon="fas fa-spinner-third" class="fa-lg fa-spin-custom" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-tab>
        <!-- Item configuration -->
        <b-tab>
            <template #title>
                {{ $t('Variables') }}
                <b-badge pill variant="info">{{
                    groups
                        .map((gr) => gr.Items.length)
                        .reduce(function (a, b) {
                            return a + b;
                        }, 0)
                }}</b-badge>
            </template>
            <!-- <template #title>
                <span class="tabHeaderText">{{$t('Variables')}}</span>
            </template> -->
            <ItemConfiguration
                ref="itemConfiguration"
                :columns="itemColumns"
                :groups="groups"
                :OnItemBrowse="onItemBrowse"
                :separateGroupConfig="true"
                :groupConfiguration="groupConfiguration"
                :readonly="!this.$store.state.auth.user.permissions.PermissionWrite"
                :updateMode="updateMode"
                :RefreshVarCounter="refreshVariablesCounter"
            />
        </b-tab>
    </b-tabs>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import ItemConfiguration from '@/components/configuration/Equipments/ItemConfiguration.vue';
import ToastAlert from '@/utils/ToastAlert';
import { uuid } from 'vue-uuid';

export default {
    name: 'ConfigOPCUA',
    props: {
        equipment: Object,
        onValidate: Function,
        updateMode: Boolean,
    },
    components: {
        ItemConfiguration,
    },
    watch: {
        async selectedSecurityMode(newVal, oldVal) {
            if (newVal == 1) {
                // None
                this.selectedSecurityPolicy = 'None';
            } else {
                // every other
                this.selectedSecurityPolicy = 'Basic128RSA15';
            }
            this.availableSecurityPolicies = [
                { value: 'None', text: 'None', disabled: newVal !== 1 },
                { value: 'Basic128RSA15', text: 'Basic128RSA15', disabled: newVal === 1 },
                { value: 'Basic256', text: 'Basic256', disabled: newVal === 1 },
                { value: 'Basic256Sha256', text: 'Basic256Sha256', disabled: newVal === 1 },
            ];
        },
    },
    computed: {
        invalidState() {
            return this.ldsAddressState === false || this.manualUrlState === false;
        },
        ldsAddressState() {
            return this.selectedConnectionType == 0 && (this.ldsAddress == null || this.ldsAddress == '' || this.ldsAddress == undefined) ? false : null;
        },
        manualUrlState() {
            return this.selectedConnectionType == 1 && (this.manualUrl == null || this.manualUrl == '' || this.manualUrl == undefined) ? false : null;
        },
        ldsServerUrls() {
            return this.availableLdsServers?.map((d) => d.Url) ?? [];
        },
        ldsServerEndpoints() {
            return this.availableLdsServers.find((d) => d.Url == this.selectedLdsServer)?.Endpoints ?? [];
        },
    },
    async mounted() {},
    data() {
        return {
            availableConnectionTypes: [
                { value: 0, text: this.$t('equipment.opcua.automatic') },
                { value: 1, text: this.$t('equipment.opcua.manual') },
            ],
            selectedConnectionType: this.equipment?.ConnectionType ?? 0,
            availableSecurityModes: [
                { value: 1, text: this.$t('equipment.opcua.securityMode.none') },
                { value: 2, text: this.$t('equipment.opcua.securityMode.sign') },
                { value: 3, text: this.$t('equipment.opcua.securityMode.signEncrypt') },
            ],
            selectedSecurityMode: this.equipment?.SecurityMode ?? 1,
            availableSecurityPolicies: [
                { value: 'None', text: 'None', disabled: false },
                { value: 'Basic128RSA15', text: 'Basic128RSA15', disabled: true },
                { value: 'Basic256', text: 'Basic256', disabled: true },
                { value: 'Basic256Sha256', text: 'Basic256Sha256', disabled: true },
            ],
            selectedSecurityPolicy: this.equipment?.SecurityPolicy ?? 'None',
            loadingLds: false,
            loadingConnectionCheck: false,
            ldsConnectionVerified: false,
            ldsAddress: this.equipment?.LdsAddress ?? 'localhost',
            manualUrl: this.equipment?.SelectedUrl ?? 'opc.tcp://localhost:49320',
            availableLdsServers: [],
            selectedLdsServer: this.equipment?.LdsServer ?? null,
            selectedLdsEndpoint: this.equipment?.SelectedUrl ?? null,
            minKeySize: this.equipment?.MinimumKeySize ?? 2048,
            username: this.equipment?.Username ?? '',
            password: this.equipment?.Password ?? '',
            itemColumns: [
                { key: 'Name', dataField: 'Name', dataType: 'string', caption: this.$t('Name'), required: true },
                { key: 'Type', dataField: 'Type', dataType: 'string', caption: this.$t('Type'), required: true },
                { key: 'Address', dataField: 'Address', dataType: 'string', caption: this.$t('Address'), required: true },
            ],
            groups: this.equipment?.Groups ?? [{ id: uuid.v4(), Name: `${this.$t('Group')} 1`, Active: true, RefreshRate: 1000, Items: [] }],
            groupConfiguration: {
                rows: [
                    {
                        columns: [{ text: this.$t('Refresh rate'), variable: 'RefreshRate', inputType: 'number', cols: '6', min: '100', max: '99999', defaultValue: 1000 }],
                    },
                ],
            },
        };
    },
    methods: {
        async saveEquipmentCommunication() {
            await this.$refs.itemConfiguration.saveEquipmentCommunication();
        },
        /**
         * Synchronizes the data changes from the wizard into the given equipment. This method should be called before updateing / saving / adding
         * the equipment in the parent control.
         * @public This method is public.
         */
        async synchronizeEquipment() {
            this.equipment.Groups = this.groups;
            this.equipment.ConnectionType = this.selectedConnectionType;
            this.equipment.SelectedUrl = this.selectedConnectionType == 0 ? this.selectedLdsEndpoint : this.manualUrl;
            this.equipment.LdsAddress = this.ldsAddress;
            this.equipment.LdsServer = this.selectedLdsServer;
            this.equipment.MinimumKeySize = this.minKeySize;
            this.equipment.SecurityMode = this.selectedSecurityMode;
            this.equipment.SecurityPolicy = this.selectedSecurityPolicy;
            this.equipment.Username = this.username;
            this.equipment.Password = this.password;
        },
        /**
         * Gets the list of servers and endpoints from the given LDS URL.
         */
        async getLdsInformation(e) {
            this.loadingLds = true;
            this.ldsConnectionVerified = false;
            const result = await ProdComEquipmentService.opcUaLdsConnect(this.ldsAddress);
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            } else {
                this.ldsConnectionVerified = result.ret.Connected;
                if (!result.ret.Connected) {
                    if (result.ConnectionFailedMessage !== '') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
                    }
                } else {
                    this.availableLdsServers = result.ret.DiscoveryUrls;
                }
            }
            this.loadingLds = false;
        },
        /**
         * Checks if the given connection parameters result in a valid connection.
         */
        async onCheckConnection(e) {
            this.loadingConnectionCheck = true;
            const result = await ProdComEquipmentService.opcUaCheckConnection(this.validate());
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            } else {
                if (!result.ret.Connected) {
                    if (result.ConnectionFailedMessage !== '') {
                        this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret.ConnectionFailedMessage), 'warning'));
                    }
                } else {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('equipment.opcua.connectionSuccess'), 'success', this.$t('ConnectionSuccess')));
                }
            }
            this.loadingConnectionCheck = false;
        },
        /**
         * Returns the correctly formated object of connection parameters
         */
        validate() {
            if (this.invalidState) return null;
            return {
                ConnectionType: this.selectedConnectionType,
                SelectedUrl: this.selectedConnectionType == 0 ? this.selectedLdsEndpoint : this.manualUrl,
                LdsAddress: this.ldsAddress,
                LdsServer: this.selectedLdsServer,
                MinimumKeySize: this.minKeySize,
                SecurityMode: this.selectedSecurityMode,
                SecurityPolicy: this.selectedSecurityPolicy,
                Username: this.username,
                Password: this.password,
            };
        },
        /**
         * Called when browsing for items in configuration. Returns the list of available items
         */
        async onItemBrowse(e) {
            // TODO: search for items in OPC UA conf
            try {
                const data = await ProdComEquipmentService.opcUaBrowseItems(this.validate());
                if (data.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
                    return [];
                } else {
                    return data.ret;
                }
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        async refreshVariablesCounter(searchingForItems, variableCounter) {
            let form_validate = this.validate();
            const res = await ProdComEquipmentService.opcUaCheckVariablesCounter(form_validate);
            if (res.success == 'y') {
                variableCounter.content = res.ret;
                variableCounter.is = true;
                while (searchingForItems.is) {
                    let res = await ProdComEquipmentService.opcUaCheckVariablesCounter(form_validate);
                    if (Number.isInteger(res.ret) && res.ret != 0) {
                        variableCounter.content = res.ret;
                    }
                    await new Promise((resolve) => setTimeout(resolve, 100));
                }
            }
        },
        async isVariablesCounter() {
            const res = await ProdComEquipmentService.opcUaCheckVariablesCounter();
            return res.success == 'y';
        },
    },
};
</script>

<style scoped lang="scss">
.icon-check {
    transition: all 0.3s;
}

.icon-check:hover {
    transform: scale(1.3);
    transition: all 0.3s;
    cursor: pointer;
}

#username[readonly],
#password[readonly] {
    opacity: 0.5;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: default !important;
    pointer-events: none !important;
    color: #dedede !important;
}
</style>